/** @format */

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  background-color: wheat;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

canvas[resize] {
  width: 90%;
  height: 90%;
  display: block;
  margin: 0 auto;
  padding: 0;
  border: 1px solid black;
  background-color: white;
}
